import { DecorateEditorialHtml, returnCitiCode } from "src/components/flexComponents/Editorial/ContentUtilities";
import * as React from "react";
import { useLocation } from "react-router";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkText } from "@egds/react-core/text";
import { UitkPrimaryButton } from "@egds/react-core/button";
import { formatText } from "bernie-l10n";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { cardLink } from "stores/wizard/config/overrideConfigBySiteId/siteIds/siteId1";
import { UitkSpacing } from "@egds/react-core/spacing";

export const CitiCardModalDialogPopUp = (props: { items: EditorialModel[] }) => {
  const { search: searchUrl } = useLocation();
  const CONTENT_ID_COUNT = 8;
  const { items } = props;

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const [
    chooseCardText,
    feeCardImage,
    feeCardSubText,
    feeCardOfferText,
    seperator,
    noFeeCardImage,
    noFeeCardSubText,
    noFeeCardOfferText,
  ] = items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />);
  const applyNowText = formatText("grid3.citiDesktopStickybar.applyNow.text");
  const citiCode = returnCitiCode(searchUrl);
  const feeCardApplyNowLink = citiCode ? cardLink.feeCardLink + `&citiCode=${citiCode}` : cardLink.feeCardLink;
  const noFeeCardApplyNowLink = citiCode ? cardLink.noFeeCardLink + `&citiCode=${citiCode}` : cardLink.noFeeCardLink;

  return (
    <UitkLayoutGrid rows={1} space="six">
      <UitkLayoutGridItem>
        <UitkText align="center" size={600} weight="bold">
          <div>{chooseCardText}</div>
        </UitkText>
      </UitkLayoutGridItem>
      <UitkLayoutGridItem>
        <div>
          <UitkSpacing padding={{ blockend: "three" }}>
            <UitkText align="center">
              <div>{feeCardImage}</div>
            </UitkText>
          </UitkSpacing>
          <UitkText align="center" size={300} weight="bold">
            <div>{feeCardSubText}</div>
          </UitkText>
          <UitkSpacing padding={{ blockend: "three" }}>
            <UitkText align="center" size={200} weight="bold">
              <div>{feeCardOfferText}</div>
            </UitkText>
          </UitkSpacing>
          <UitkText align="center">
            <UitkPrimaryButton type="button" href={feeCardApplyNowLink}>
              {applyNowText}
            </UitkPrimaryButton>
          </UitkText>
        </div>
      </UitkLayoutGridItem>

      <UitkLayoutGridItem>
        <UitkText align="center">
          <div>{seperator}</div>
        </UitkText>
      </UitkLayoutGridItem>

      <UitkLayoutGridItem>
        <div>
          <UitkSpacing padding={{ blockend: "three" }}>
            <UitkText align="center">
              <div>{noFeeCardImage}</div>
            </UitkText>
          </UitkSpacing>
          <UitkText align="center" size={300} weight="bold">
            <div>{noFeeCardSubText}</div>
          </UitkText>
          <UitkSpacing padding={{ blockend: "three" }}>
            <UitkText align="center" size={200} weight="bold">
              <div>{noFeeCardOfferText}</div>
            </UitkText>
          </UitkSpacing>
          <UitkText align="center">
            <UitkPrimaryButton type="button" href={noFeeCardApplyNowLink}>
              {applyNowText}
            </UitkPrimaryButton>
          </UitkText>
        </div>
      </UitkLayoutGridItem>
    </UitkLayoutGrid>
  );
};
export default CitiCardModalDialogPopUp;
